<template>
  <div class="w-auto md:w-64 lg:w-80 xl:w-96">
    <form
      :class="[isDarkMode ? 'bg-gray-800' : 'bg-white']"
      class="shadow-md rounded px-8 pt-6 pb-8 mb-4"
      @submit.prevent="submitForm"
    >
      <div class="mb-4">
        <div class="flex pb-4">
          <p
            v-if="!hasAcceptedCookie"
            :class="[isDarkMode ? 'text-red-500' : 'text-red-600']"
            class="text-sm text-justify"
          >
            <span class="font-semibold text-md">Important note:</span>
            <br /><br />To block spammers, we use Google ReCaptcha. That means,
            you can send me message only after accepting our privacy policy and
            cookie policy from the modal located in the bottom of the screen.
            <br /><br />
            If the consent modal is closed, you can click or press on the yellow
            cookie
            <font-awesome-icon
              class="text-yellow-400 text-base"
              :icon="['fa', 'cookie']"
            />
            in the right bottom of the screen to open it.
          </p>
        </div>
        <div class="mb-4">
          <label
            :class="[isDarkMode ? 'text-gray-100' : 'text-gray-700']"
            class="required block text-sm font-bold mb-2"
            for="email"
          >
            Email
          </label>
          <input
            id="email"
            v-model="form.email"
            :disabled="!hasAcceptedCookie"
            autocomplete="off"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="email@example.com"
          />
        </div>
        <label
          :class="[isDarkMode ? 'text-gray-100' : 'text-gray-700']"
          class="required block text-sm font-bold mb-2"
          for="firstname"
        >
          First name
        </label>
        <input
          id="firstname"
          v-model="form.firstname"
          :disabled="!hasAcceptedCookie"
          autocomplete="off"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="first name"
        />
      </div>
      <div class="mb-4">
        <label
          :class="[isDarkMode ? 'text-gray-100' : 'text-gray-700']"
          class="block text-sm font-bold mb-2"
          for="lastname"
        >
          Last name
        </label>
        <input
          id="lastname"
          v-model="form.lastname"
          :disabled="!hasAcceptedCookie"
          autocomplete="off"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="last name"
        />
      </div>
      <div class="mb-4">
        <label
          :class="[isDarkMode ? 'text-gray-100' : 'text-gray-700']"
          class="block text-sm font-bold mb-2"
          for="phone"
        >
          Phone number
        </label>
        <input
          id="phone"
          v-model="form.phone"
          :disabled="!hasAcceptedCookie"
          autocomplete="off"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="+45 98 76 54 32 10"
        />
      </div>
      <div class="mb-4">
        <label
          :class="[isDarkMode ? 'text-gray-100' : 'text-gray-700']"
          class="required block text-sm font-bold mb-2"
          for="message"
        >
          Message
        </label>
        <textarea
          id="message"
          v-model="form.message"
          :disabled="!hasAcceptedCookie"
          autocomplete="off"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          rows="5"
          placeholder="..."
        />
      </div>
      <div class="flex flex-row-reverse items-center justify-between py-2">
        <button
          class="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:bg-gray-500"
          :disabled="!hasAcceptedCookie"
          type="submit"
        >
          Contact
        </button>
      </div>
      <div class="flex pt-4">
        <p
          v-if="errored"
          :class="[isDarkMode ? 'text-red-500' : 'text-red-600']"
          class="text-sm"
        >
          An error has occurred! Please try again.
          <br />{{ errorMessage }}
        </p>
        <p v-if="success" class="font-semibold text-green-600">
          Thank you for contacting me.
          <br />
          Your message is received!
        </p>
      </div>
    </form>
  </div>
</template>

<style scoped>
label.required:after {
  color: red;
  content: "*";
  font-size: 18px;
}
</style>

<script>
import axios from "axios";

export default {
  name: "ContactForm",
  data() {
    return {
      errored: false,
      errorMessage: "",
      success: false,
      form: {
        email: "",
        firstname: "",
        lastname: "",
        phone: "",
        message: "",
      },
    };
  },
  computed: {
    hasAcceptedCookie() {
      return !!this.$store.getters.hasAcceptedCookie;
    },
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
  },
  methods: {
    submitForm() {
      this.errored = false;
      const greCaptcha = window.grecaptcha;
      if (!greCaptcha) {
        this.errored = true;
        return;
      }
      greCaptcha.ready(() => {
        greCaptcha
          .execute("6Ldv918gAAAAAK-23b0-i_VCcmoR1JMlWkK1iBxy", {
            action: "submit_contact_form",
          })
          .then((token) => {
            // TODO: This should be in a dedicated service
            // TODO: Use env variable
            const basePath = "https://api.pooyan.info";
            this.form.reCaptchaToken = token;
            axios
              .post(basePath + "/contact", this.form)
              .then((res) => {
                console.log(res);
                this.resetForm();
                this.success = true;
              })
              .catch((error) => {
                console.log(error);
                this.errorMessage = error.error;
                this.errored = true;
              })
              .finally(() => {
                console.log("Form submitted!");
              });
          });
      });
    },
    resetForm() {
      this.form = {
        reCaptchaToken: "",
        email: "",
        firstname: "",
        lastname: "",
        phone: "",
        message: "",
      };
    },
  },
};
</script>
